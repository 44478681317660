<template>
    <div id="error">
        <ErrorItem/>
    </div>
</template>

<script>
import ErrorItem from "@/components/ErrorItem.vue";

export default {
name: "ErrorView",
    components: {ErrorItem}
}
</script>

<style scoped>

</style>