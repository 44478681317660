<template>
    <div>
    <br></br><br></br>
    <footer class="footer">
      <div class="content has-text-centered">
        <p>Our easy-to-use URL shortener allows you to quickly and easily shorten long URLs into custom, easy-to-remember links that you can share with your audience.</p>
        <p>More Exciting Features Coming Soon!</p>
        <p>© SureLink 2023</p>
      </div>
    </footer>
    </div>
</template>
<script>
export default {
    name: 'FooterItem'
}
</script>
<style scoped lang="scss">
    .footer {
      background-color: #2C3333;
      color: #fff;
      padding: 20px;
      margin-top: 20px;
    }
</style>